import { FinePrint } from '../../models/fineprint';

export default new FinePrint('CCB Points Program', [
    {
        title: 'Why this Program?',
        details: [
            'We love when people spread the joy of gaming! We’d rather share our revenues with the community that supports us than with ad services.',
        ],
    },
    {
        title: 'What do you get?',
        details: [
            'Referee gets 10% discount on purchase',
            'Referrer gets 10% discount on subsequent purchases',
        ],
    },
    {
        title: 'How does it work?',
        details: [
            'Host a Cryptic Cardboard game and post about it on Instagram or Twitter. Tag us and your fellow gamers at the table. You can find us at:',
            '<a href="https://www.instagram.com/crypticcardboard/">https://www.instagram.com/crypticcardboard/</a>',
            'and here:',
            '<a href="https://twitter.com/crypticcbgames">https://twitter.com/crypticcbgames</a>',
            'We will send discount codes to the people tagged in the photo, that will allow them to purchase the game being hosted at 10% off. These are Referee Codes.',
            'When any of the Referee Codes get used, you (the host) get a Referral Code worth 10% of the game’s listed price as store credit.',
        ],
    },
    {
        title: 'General',
        details: [
            'Codes valid only on ccbgames.in site purchases.',
            'SM post must be non-expiring.',
            'Everyone who is tagged must be visible in the photo that is shared.',
            'We calculate 10% based on the listed price of the hosted game on our website at the time that the codes are generated.',
            'Terms and Conditions for the CCB Points Program are subject to change without prior notice.',
        ],
    },
    {
        title: 'Referee Codes',
        details: [
            'Only one Referree code per person can be applied to a purchase.',
            'Referree Codes are valid for 2 months.',
            'Codes are non-transferrable.',
            'Can only be used for the game tabled in the Post.',
        ],
    },
    {
        title: 'Referral Codes',
        details: [
            'Generated once for each referee code which is used',
            'Valid for 6 months from the time it is sent to the host.',
            'Referral codes are transferable.',
            'Multiple referral codes can be combined for any purchase made on ccbgames.in.',
        ],
    },
]);
