<template>
    <div id="footer" class="footer">
        <div class="column">
            <span class="footer__text text-xs">
                CONTACT US<br/>
                <div class="flex flex-row gap-x-1 items-center">
                    <img class="footer__icon" src="https://detangled.imgix.net/images/email.png?auto=format" />
                    <a href="mailto:hello@ccbgames.in">hello@ccbgames.in</a><br/>
                </div>
                <div class="flex flex-row gap-x-1 items-center">
                    <img class="footer__icon" src="https://detangled.imgix.net/images/telephone.png?auto=format" />
                    <a href="tel:+91-85914-85601">+91 85914 85601</a>
                </div>
            </span>

            <div class="hr md:hidden" />

            <span class="footer__text text-xs md:hidden justify-around">
                <router-link to="/refunds">Returns, Refunds and Cancellations</router-link><br/>
                <router-link to="/points">Points Program</router-link><br/>
                <router-link to="/privacy">Privacy Policy</router-link><br/>
                <router-link to="/faqs">FAQs</router-link><br/>
                <router-link to="/toc">Terms of Use</router-link><br/>
            </span>
            <div class="footer__text text-fine md:hidden">
                © Copyright 2021. All rights Reserved.
            </div>
        </div>


        <div class="hidden md:block vr" />
        <div class="hidden md:block footer__text text-xs">
            <router-link to="/refunds">Returns, Refunds and Cancellations</router-link><br/>
            <router-link to="/points">Points Program</router-link><br/>
            <router-link to="/privacy">Privacy Policy</router-link><br/>
            <router-link to="/faqs">FAQs</router-link><br/>
            <router-link to="/toc">Terms of Use</router-link><br/>
        </div>

        <div class="vr" />
        <div class="column justify-evenly md:justify-start">
            <div class="footer__social">
                <a href="https://www.instagram.com/crypticcardboard/">
                    <img class="footer__image" src="https://detangled.imgix.net/images/instagram.svg?auto=format" />
                </a>
                <a href="https://www.facebook.com/crypticcardboard/">
                    <img class="footer__image" src="https://detangled.imgix.net/images/facebook.svg?auto=format" />
                </a>
                <a href="https://twitter.com/crypticcbgames">
                    <img class="footer__image" src="https://detangled.imgix.net/images/twitter.svg?auto=format" />
                </a>
            </div>
            <div class="hidden md:block footer__text text-fine">
                © Copyright 2021. All rights Reserved.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        getScreenResolution() {
            return screen.width;
        },
    },
};
</script>

<style scoped>

.column {
    @apply flex flex-col gap-y-2;
}

.footer {
    @apply
        flex flex-row
        bg-ccb-teal-400
        w-full h-56
               md:h-36

        justify-around items-stretch
        md:justify-center
        gap-x-6 p-6;
}

.footer__text {
    @apply font-nunito text-ccb-teal-font;
}

.vr {
    width: 1px;
    background: rgba(255, 255, 255, 0.6);
}

.hr {
    height: 1px;
    background: rgba(255, 255, 255, 0.6);
}

.footer__icon {
    @apply h-2 w-2;
}

.footer__image {
    @apply h-10 w-10
        md:h-8 md:w-8;
}

.footer__social {
    @apply flex justify-around w-full gap-y-2
        flex-col md:flex-row;
}

</style>