import 'tailwindcss/tailwind.css';
import { createApp } from 'vue';
import { event, set } from 'vue-gtag';
import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router';

// The following dependency is added to enable winston logger to work correctly.
// Without the following import we are unable to run the logger. See the bug and
// relevant details at
// https://github.com/winstonjs/winston/issues/1354#issuecomment-426433071
import 'setimmediate';

import LoadScript from 'vue-plugin-load-script';
import VueGTag from 'vue-gtag';

import path from 'path';
import winston from 'winston';

import { games, models } from '@ccb/utils';

import { logger } from './utils';

import App from '@/App.vue';
import GameList from '@/views/GameList.vue';

const Checkout = () => import('@/views/Checkout.vue');
const Game = () => import('@/views/Game.vue');
const Invoice = () => import('@/views/Invoice.vue');
const NotFound = () => import('@/views/NotFound.vue');
const Payment = () => import('@/views/Payment.vue');
const Policy = () => import('@/views/Policy.vue');
const Coupon = () => import('@/views/Coupon.vue');
const Editor = () => import('@/views/Editor.vue');

import faqs from '@/assets/policies/faqs';
import privacy from '@/assets/policies/privacy';
import refunds from '@/assets/policies/refunds';
import toc from '@/assets/policies/toc';
import points from '@/assets/policies/points';

import api from '@/models/api';

logger.add(
    new winston.transports.Http({
        level: 'error',
        host: window.location.hostname,
        port: Number(window.location.port) || undefined,
        ssl: window.location.protocol === 'https:',
        path: path.join(api.defaults.baseURL || `/api`, 'frontend-errors'),
    })
);

const routes: RouteRecordRaw[] = [
    { path: '/payment/:receiptId', component: Payment, props: true },
    { path: '/invoice/:receiptId', component: Invoice, props: true },

    { path: '/faqs', component: Policy, props: { policy: faqs } },
    { path: '/privacy', component: Policy, props: { policy: privacy } },
    { path: '/refunds', component: Policy, props: { policy: refunds } },
    { path: '/toc', component: Policy, props: { policy: toc } },

    { path: '/referral', redirect: '/points' },
    { path: '/points', component: Policy, props: { policy: points } },
    {
        path: '/DROW8HYBVATS/admin/dashboard/coupons/:id?',
        component: Coupon,
        props: true,
    },
    {
        path: '/DROW8HYBVATS/admin/dashboard/:collection/:id?',
        component: Editor,
        props: true,
    },

    { path: '/', component: GameList, props: { games: Object.values(games) } },
    { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
];

for (const gameKey in games) {
    const gameObject = (games as any)[gameKey] as models.Game;
    const canonical = gameObject.canonicalName;

    routes.push({
        path: `/${canonical}`,
        component: Game,
        props: { game: gameObject },
        meta: { title: `${gameObject.name} | Game` },
    });

    if (!('sale' in gameObject)) continue;
    const sellable = gameObject as models.SellableGame;

    routes.push({
        path: `/${canonical}/checkout`,
        component: Checkout,
        meta: { title: `${sellable.name} | Game | Buy` },
        props: { game: sellable },
    });
}

const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL),

    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) return { ...savedPosition, behavior: 'smooth' };
        return { top: 0, behavior: 'smooth' };
    },
});

router.beforeEach((to) => {
    window.document.title = (to.meta.title as string) || 'Cryptic Cardboard';
    event('page_view', { page_location: to.fullPath });
    return true;
});

set({ currency: 'INR' });
if (process.env.NODE_ENV !== 'production') set({ debug_mode: 1 });

const vue = createApp(App);

// Add GTAG Analytics.
vue.use(
    VueGTag,
    {
        config: { id: 'G-LHK293V020' },
        // Disable except when we are in production.
        disableScriptLoad: process.env.NODE_ENV !== 'production',
    },
    router
);

vue.use(router);
vue.use(LoadScript);
vue.mount('#app');
