<template>
<div class="flex flex-col items-start md:items-center justify-center max-w-screen-2xl bg-white w-full md:w-auto">
    <navbar />
    <router-view></router-view>
    <custom-footer />
</div>
</template>

<script>

import CustomFooter from '@/components/CustomFooter.vue'
import Navbar from '@/components/Navbar.vue';
import { logger } from './utils';
export default {
    components: { CustomFooter, Navbar },
    errorCaptured: function(err, instance, info) {
        logger.error(err, { instance, info });
        return false;
    }
}

</script>

<style>

body {
    padding: 0px;
    margin: 0px;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    width: 100%;
    overflow-x: clip;
}

</style>
