import winston from 'winston';

export default winston.createLogger({
    level: process.env.NODE_ENV !== 'development' ? 'info' : 'debug',
    format: winston.format.combine(
        winston.format.errors({ stack: true }),
        winston.format.timestamp({ format: 'isoDateTime' }),
        winston.format.align(),
        winston.format.printf(
            (info) => `${info.level} - ${info.timestamp}: ${info.message}`
        )
    ),
    transports: [],
});
