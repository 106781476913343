import { FinePrint } from '../../models/fineprint';

export default new FinePrint('Terms and Conditions', [
    {
        details: [
            'PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.',
            'This website is owned and operated by Cryptic Cardboard LLP.',
            'The terms "We", "Us", "Our" shall mean Cryptic Cardboard LLP and the terms "Visitor", "User", "You", "Your" refer to the users.',
            'This Terms of Service Agreement (the "Agreement") governs your use of this website, www.ccbgames.in ("the Website") or your purchase of products available on this Website. This Agreement includes, and incorporates by this reference, the policies and guidelines referenced below. Cryptic Cardboard LLP reserves the right to change or revise the terms and conditions of this Agreement at any time by posting any changes or a revised Agreement on this Website, without any prior written notice to you. The changed or revised Agreement will be effective immediately after it is posted on this Website. Your use of the Website following the posting of any such changes or of a revised Agreement will constitute your acceptance of any such changes or revisions. We encourage you to review this Agreement whenever you visit the Website to make sure that you understand the terms and conditions governing use of the Website. This Agreement does not alter in any way the terms or conditions of any other written agreement you may have with Cryptic Cardboard LLP for other products or services. If you do not agree to this Agreement (including any referenced policies or guidelines), please immediately terminate your use of the Website.',
        ],
    },

    {
        title: 'I. PRODUCTS',
        details: [
            'Terms of Offer. This Website offers for sale certain products ( "Products"). By placing an order for Products through this Website, you agree to the terms set forth in this Agreement.',
            'Proprietary Rights. Cryptic Cardboard LLP has proprietary rights and trade secrets in the Products. You may not copy, reproduce, resell or redistribute any Product manufactured and/or distributed by Cryptic Cardboard LLP.',
        ],
    },

    {
        title: 'II. WEBSITE',
        details: [
            'Content; Intellectual Property; Third Party Links. ',
            'In addition to making Products available, this Website also offers information and marketing materials. This Website also offers information, both directly and through indirect links to third-party websites. Cryptic Cardboard LLP does not always create the information offered on this Website; instead the information is often gathered from other sources. To the extent that Cryptic Cardboard LLP does create the content on this Website, such content is protected by intellectual property laws of India, foreign nations, and international bodies. Unauthorized use of the material may violate copyright, trademark, and/or other laws. You acknowledge that your use of the content on this Website is for personal, noncommercial use. Any links to third-party websites are provided solely as a convenience to you. Cryptic Cardboard LLP does not endorse the contents on any such third-party websites. Cryptic Cardboard LLP is not responsible for the content of or any damage that may result from your access to or reliance on these third-party websites. If you link to third-party websites, you do so at your own risk. ',
            'Use of Content: All logos, brands, marks headings, labels, names, signatures, layouts, numerals, shapes or any combinations thereof, appearing in this site, except as otherwise noted, are properties either owned, or used under licence, by the business and / or its associate entities who feature on this Website. The use of these properties or any other content on this site, except as provided in these terms and conditions or in the site content, is strictly prohibited. You may not sell or modify the content of this Website or reproduce, display, publicly perform, distribute, or otherwise use the materials in any way for any public or commercial purpose without the respective organisation’s or entity’s written permission.',
            'Use of Website: Cryptic Cardboard LLP is not responsible for any damages resulting from use of this website by anyone. You will not use the Website for illegal purposes. You will (1) abide by all applicable local, state, national, and international laws and regulations in your use of the Website (including laws regarding intellectual property), (2) not interfere with or disrupt the use and enjoyment of the Website by other users, (3) not resell material on the Website, (4) not engage, directly or indirectly, in transmission of "spam", chain letters, junk mail or any other type of unsolicited communication, and (5) not defame, harass, abuse, or disrupt other users of the Website.',
            'License. By using this Website, you are granted a limited, non-exclusive, non-transferable right to use the content and materials on the Website in connection with your normal, noncommercial, use of the Website. You may not copy, reproduce, transmit, distribute, or create derivative works of such content or information without express written authorization from Cryptic Cardboard LLP or the applicable third party (if third party content is at issue).',
            'Posting. By posting, storing, or transmitting any content on the Website, you hereby grant Cryptic Cardboard LLP a perpetual, worldwide, non-exclusive, royalty-free, assignable, right and license to use, copy, display, perform, create derivative works from, distribute, have distributed, transmit and assign such content in any form, in all media now known or hereinafter created, anywhere in the world. Cryptic Cardboard LLP does not have the ability to control the nature of the user-generated content offered through the Website. You are solely responsible for your interactions with other users of the Website and any content you post. Cryptic Cardboard LLP is not liable for any damage or harm resulting from any posts by or interactions between users. Cryptic Cardboard LLP reserves the right, but has no obligation, to monitor interactions between and among users of the Website and to remove any content Cryptic Cardboard LLP deems objectionable.',
        ],
    },

    {
        title: 'III. DISCLAIMER OF WARRANTIES',
        details: [
            'The website and its products are offered on an "as is" and "as available" basis.',
            'No advice or information, whether oral or written, obtained by you from this website, will create any warranty not expressly stated herein.',
        ],
    },

    {
        title: 'IV. LIMITATION OF LIABILITY',
        details: [
            'Entire liability, and your exclusive remedy, in law, in equity, or otherwise, with respect to the website content and products and/or for any breach of this agreement is solely limited to the amount you paid, for products purchased via the website.   ',
            'Cryptic Cardboard LLP will not be liable for any direct, indirect, incidental, special or consequential damages in connection with this agreement or the products in any manner, including liabilities resulting from (1) the use or the inability to use the website content or products; (2) The cost of procuring substitute products or content; (3) Any products purchased or obtained or transactions entered into through the website; or (4) Any lost profits you allege.',
        ],
    },

    {
        title: 'V. INDEMNIFICATION',
        details: [
            "You will release, indemnify, defend and hold harmless Cryptic Cardboard LLP, and any of its contractors, agents, employees, officers, directors, shareholders, affiliates and assigns from all liabilities, claims, damages, costs and expenses, including reasonable attorneys' fees and expenses, of third parties relating to or arising out of (1) this Agreement or the breach of your warranties, representations and obligations under this Agreement; (2) the Website content or your use of the Website content; (3) the Products or your use of the Products (including Trial Products); (4) any intellectual property or other proprietary right of any person or entity; (5) your violation of any provision of this Agreement; or (6) any information or data you supplied to Cryptic Cardboard LLP. The terms of this provision will survive any termination or cancellation of this Agreement or your use of the Website or Products.",
        ],
    },

    {
        title: 'VI. PRIVACY',
        details: [
            'Cryptic Cardboard LLP believes strongly in protecting user privacy. Please refer to our privacy policy, incorporated by reference herein, that is posted on the Website.',
        ],
    },

    {
        title: 'VII. AGREEMENT TO BE BOUND',
        details: [
            'By using this Website or ordering Products, you acknowledge that you have read and agree to be bound by this Agreement and all terms and conditions on this Website. ',
        ],
    },

    {
        title: 'VIII. GENERAL',
        details: [
            'Force Majeure. Cryptic Cardboard LLP will not be deemed in default hereunder or held responsible for any cessation, interruption or delay in the performance of its obligations hereunder due to earthquake, flood, fire, storm, natural disaster, act of God, war, terrorism, armed conflict, labor strike, lockout, or boycott.',
            'Cessation of Operation. Cryptic Cardboard LLP may at any time, in its sole discretion and without advance notice to you, cease operation of the Website and distribution of the Products.',
            'Entire Agreement. This Agreement comprises the entire agreement between you and Cryptic Cardboard LLP and supersedes any prior agreements pertaining to the subject matter contained herein.',
            "Effect of Waiver. The failure of Cryptic Cardboard LLP to exercise or enforce any right or provision of this Agreement will not constitute a waiver of such right or provision. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of this Agreement remain in full force and effect.",
            'Statute of Limitation. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Website or Products or this Agreement must be filed within one (1) year after such claim or cause of action arose or be forever barred.',
            'Governing Law and jurisdiction of this agreement shall be as described in Dispute Resolution.',
            'Waiver of Class Action Rights. By entering into this agreement, you hereby irrevocably waive any right you may have to join claims with those of others in the form of a class action or similar procedural device. Any claims arising out of, or relating to, or in connection with this agreement must be asserted individually.',
            'Termination. Cryptic Cardboard LLP reserves the right to terminate your access to the Website if it reasonably believes, in its sole discretion, that you have breached any of the terms and conditions of this Agreement. Following termination, you will not be permitted to use the Website and Cryptic Cardboard LLP may, in its sole discretion and without advance notice to you, cancel any outstanding orders for Products. If your access to the Website is terminated, we reserve the right to exercise whatever means it deems necessary to prevent unauthorized access of the Website. This Agreement will survive indefinitely unless and until Cryptic Cardboard LLP chooses, in its sole discretion and without advance to you, to terminate it.',
            'Domestic Use. Cryptic Cardboard LLP makes no representation that the Website or Products are appropriate or available for use in locations outside India. Users who access the Website from outside India do so at their own risk and initiative and must bear all responsibility for compliance with any applicable local laws.',
            'Assignment. You may not assign your rights and obligations under this Agreement to anyone. Cryptic Cardboard LLP may assign its rights and obligations under this Agreement in its sole discretion and without advance notice to you.',
            'BY USING THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.',
        ],
    },

    {
        title: 'DISPUTE RESOLUTION',
        details: [
            'This Website originates from Mumbai, Maharashtra. This Agreement will be governed by the laws of the State of Maharashtra without regard to its conflict of law principles to the contrary. Neither you nor Cryptic Cardboard LLP will commence or prosecute any suit, proceeding or claim to enforce the provisions of this Agreement, to recover damages for breach of or default of this Agreement, or otherwise arising under or by reason of this Agreement, other than in courts located in the State of Maharashtra. By using this Website or ordering Products, you consent to the jurisdiction and venue of such courts in connection with any action, suit, proceeding or claim arising under or by reason of this Agreement. You hereby waive any right to trial by jury arising out of this Agreement and any related documents.',
        ],
    },
]);
