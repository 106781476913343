import { FinePrint } from '../../models/fineprint';

export default new FinePrint('Returns, Refunds, and Cancellations', [
    {
        title: 'LIMITATIONS',
        details: [
            'The following applies only to products sold by Cryptic Cardboad LLP. Third party products are handled entirely by third parties and CCB polices do not cover their service.',
        ],
    },
    {
        title: 'RETURNS AND REFUNDS',
        details: [
            'All of our products go through quality control checks. In case the product arrives in a damaged state, please inform us within 24 hours of receiving the product. We will arrange for a replacement at our discretion, after verification of the damage.',
            'In case of any missing components, please inform us within 7 days of receipt and we will help you with the same.',
            'We do not accept returns.',
        ],
    },
    {
        title: 'CANCELLATIONS',
        details: [
            'Cancellations will be accepted provided the order has not been dispatched. Once a cancellation has been requested, it takes 2-5 business days for a refund to be initiated. Transaction fees and logistic fees, if any, will be subtracted from the refund amount. Post that, it could take 10-15 days for the amount to reflect in your account.',
            'Pre-orders:  We need to lock in the number of pre-order copies as some content is exclusive to pre-orders. Therefore, we do not accept cancellations beyond 14 days of order placement.',
        ],
    },
    {
        title: 'UNDELIVERED PRODUCTS',
        details: [
            'We use multiple shipping partners and they have different policies concerning undelivered items. If a product gets returned to us due to failed delivery attempts, we will refund the order amount after subtracting two-way shipping charges and transaction fees, if any.',
            'For all refunds, we use the same mode of payment that you used, to process your refund. If due to any technical issues we are unable to process the payment back to the source, we will get in touch with you for an alternative mode of payment.',
            'In case of any problems with your order, please email us at orders@ccbgames.in',
        ],
    },
]);
