import { FinePrint } from '../../models/fineprint';

export default new FinePrint('FAQs', [
    {
        title: 'Are your games original?',
        details: [
            'All the games currently on our website are made under license from the original publishers. In case of any queries or concerns regarding any of our published games, please email us at hello@ccbgames.in or Whatsapp on +91 8591485601 and we will be happy to help. ',
        ],
    },

    {
        title: 'What are your shipping charges? ',
        details: [
            'We provide free shipping on all orders unless otherwise stated.',
        ],
    },

    {
        title: 'How long does it take for orders to ship?',
        details: [
            'We try our best to dispatch orders between 5-7 days of order confirmation. You should receive your order within 1-10 days after dispatch depending on your location.',
        ],
    },

    {
        title: 'Do you accept cancellations on pre-orders?',
        details: [
            'We need to lock in the number of pre-order copies as some content is exclusive to pre-orders. Therefore, we do not accept cancellations beyond 14 days of order placement.',
        ],
    },

    {
        title: 'I would like to cancel my order. What is the process?',
        details: [
            'Cancellations can be accepted provided your order has not been dispatched. Please refer to our Returns, Refunds, and Cancellations Policy.',
        ],
    },

    {
        title: 'Do you allow returns?',
        details: [
            'We generally do not accept returns. If there is a problem with your order, please email us at orders@ccbgames.in.',
        ],
    },

    {
        title: 'My game is missing a component. What should I do?',
        details: [
            'Our games go through stringent quality control checks. In the rare event that your order has a damaged or missing component, please email us at orders@ccbgames.in within 7 days of receiving your order and we will send you a replacement for the component.',
        ],
    },

    {
        title: 'How do I get updates and additional information about your products?',
        details: [
            'You can follow us on social media through the links on this website.',
        ],
    },

    {
        title: 'HOW DO I CONTACT YOU?',
    },
    {
        title: 'I am a publisher/designer and would like to discuss localization/licensing/other collaborations.',
        details: [
            'Please email us at hello@ccbgames.in and we will get back to you as soon as possible.',
        ],
    },

    {
        title: 'I am a game designer and I want to submit a game.',
        details: [
            'We are happy to talk to game designers. However, we are not looking for the following at this point: ‘roll and move’ games, games that rely on luck more than strategy, games specifically aimed at children, rehashed versions of existing games.',
        ],
    },

    {
        title: 'I am an artist/graphic designer/illustrator. ',
        details: [
            'Please email us a link to your portfolio at hello@ccbgames.in',
        ],
    },

    {
        title: 'I have superpowers/magical abilities.',
        details: ['Please use telepathy.'],
    },
]);
