<template>
    <div class="flex flex-col w-full py-4 gap-y-4">
        <template v-if="availableGames.length > 0">
            <game-banner v-for="game in availableGames" :key="game.name" :game="game" />
        </template>

        <template v-if="preorderGames.length > 0">
            <game-banner v-for="game in preorderGames" :key="game.name" :game="game" />
        </template>

        <template v-if="upcomingGames.length > 0">
            <game-banner v-for="game in upcomingGames" :key="game.name" :game="game" />
        </template>
    </div>
</template>

<script>

import GameBanner from '@/components/GameBanner.vue';

export default {
    props: [ 'games' ],
    computed:  {
        availableGames() {
            return this.games.filter(game => game.state === 'available')
        },
        preorderGames() {
            return this.games.filter(game => game.state === 'preorder')
        },
        upcomingGames() {
            return this.games.filter(game => game.state === 'upcoming')
        },
    },
    components: { GameBanner },
}
</script>