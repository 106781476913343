<template>
    <div class="navbar">
        <router-link to="/">
            <img class="logo" alt="Company Logo" src="https://detangled.imgix.net/images/ccb-logo-transparent-bg.png?auto=format" />
        </router-link>
        <span class="brand">Cryptic Cardboard</span>
        <!-- Hamburger by Marie Van den Broeck from the Noun Project -->
        <!-- <img class="h-4 ml-auto" src="../assets/images/hamburger.svg" /> -->
    </div>
</template>
<style scoped>

.navbar {
    @apply flex flex-row items-center gap-x-4
        w-full bg-ccb-black-400

        h-14 px-4
        md:h-24 md:justify-center md:pr-64;
}

.logo {
    @apply
    
        h-8
        md:h-16;
}

.brand {
    @apply text-white align-bottom font-bebas-neue
    
        text-3xl
        md:text-4xl md:mr-48;

    /* Cannot seem to apply this directly using text-3xl or other size classes
    font-size: 1.77em; */
}

</style>