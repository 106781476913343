import { FinePrint } from '../../models/fineprint';

export default new FinePrint('Privacy Policy', [
    {
        details: [
            'This Privacy Policy applies to www.ccbgames.in.',
            'The terms “We”, “Us”,” Our” shall mean Cryptic Cardboard LLP and the terms “Visitor”, ”User”, “You”, “Your” refer to the users.',
            'We recognise the importance of maintaining your privacy. This Policy describes how we treat user information we collect on www.ccbgames.in and other offline sources. This Privacy Policy applies to current and former visitors to our website and to our online customers. By visiting and/or using our website, you agree to this Privacy Policy. ',
        ],
    },

    {
        title: 'INFORMATION WE COLLECT',
        details: [
            'Contact information. We might collect your name, email, mobile number, phone number, street, city, state, pincode, country and ip address.',
            'Payment and billing information. We collect your billing name, billing address and payment method when you buy a product. We NEVER store your credit/debit card number, expiry date or other details pertaining to your credit/debit card on our website. ',
            'Information you post. We collect information you post in a public space on our website or on a third-party social media site belonging to Cryptic Cardboard LLP',
            "Other information. If you use our website, we may collect information about your IP address and the browser you're using. We might look at what site you came from, duration of time spent on our website, pages accessed or what site you visit when you leave us. We might also collect the type of mobile device you are using, or the version of the operating system your computer or device is running.",
        ],
    },

    {
        title: 'HOW DO WE COLLECT INFORMATION?',
        details: [
            'We collect information directly from you when you submit forms on our website.',
            'We collect information from you passively. We use tracking tools like Google Analytics, Google Webmaster, browser cookies and web beacons for collecting information about your usage of our website.',
            'We get information about you from third parties. For example, if you use an integrated social media feature on our websites.  ',
        ],
    },

    {
        title: 'USE OF YOUR PERSONAL INFORMATION',
        details: [
            'We use information to contact you: We might use the information you provide to contact you for confirmation of a purchase on our website or for other promotional purposes.',
            'We use information to respond to your requests or questions. We might use your information to confirm your registration for an event or contest.',
            'We use information to improve our products and services. We might use your information to customize your experience with us. This could include displaying content based upon your preferences.',
            'We use information to look at site trends and customer interests. We may use your information to make our website and products better. We may combine information we get from you with information about you we get from third parties.',
            'We use information for security purposes. We may use information to protect our company, our customers, or our websites.',
            "We use information for marketing purposes. We might send you information about special promotions or offers. We might also tell you about new features or products. These might be our own offers or products, or third-party offers or products we think you might find interesting. Or, for example, if you buy tickets from us we'll enroll you in our newsletter.",
            'We use information to send you transactional communications. We might send you emails or SMS about your account or a ticket purchase.',
            'We use information as otherwise permitted by law.',
        ],
    },

    {
        title: 'SHARING OF INFORMATION WITH THIRD-PARTIES',
        details: [
            'We may share information with third parties who perform services on our behalf. For instance, we share information with vendors who help us manage our online payment processors or transactional message processors. Some vendors may be located outside of India.',
            'We may share information if we think we have to in order to comply with the law or to protect ourselves. We will share information to respond to a court order or subpoena. We may also share it if a government agency or investigatory body requests. Or, we might also share information when we are investigating potential fraud.',
            'We may share information with any successor to all or part of our business. For example, if part of our business is sold we may give our customer list as part of that transaction.',
            'We may share your information for reasons not described in this policy. We will tell you before we do this.',
        ],
    },

    {
        title: 'EMAIL OPT-OUT',
        details: [
            'You can opt out of receiving our marketing emails. It may take about ten days to process your request. Even if you opt out of getting marketing messages, we will still be sending you transactional messages through email/Whatsapp/SMS about your purchases.',
        ],
    },

    {
        title: 'THIRD-PARTY SITES',
        details: [
            'If you click on one of the links to third party websites, you may be taken to websites we do not control. This policy does not apply to the privacy practices of those websites. Read the privacy policy of other websites carefully. We are not responsible for these third-party sites.',
        ],
    },

    {
        title: 'UPDATES TO THIS POLICY',
        details: [
            'From time to time we may change our privacy practices. Please check our privacy policy periodically for updates.',
        ],
    },
    {
        title: 'JURISDICTION',
        details: [
            "If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy and the website's terms of use. In addition to the foregoing, any disputes arising under this Policy shall be governed by the laws of India.",
        ],
    },
]);
