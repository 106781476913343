<!-- 
    A game banner is an interactable element displaying a large hero image for
    the game along with some interactable elements.

    The wide view format which applies to all views which are wider than mobile
    is a hero view which sprawls across the page filled with the headline image
    overlaid with CTAs and blurbs.

    This component takes a game object as props input and renders the component
    with links to appropriate paths on CTAs.
-->

<template>
    <router-link :to="`/${game.canonicalName}`">
    <div class="w-full max-w-full h-64 relative">
        <img :src="heroPath"
            :alt="`${game.name} Hero`"
            :class="{ 'cursor-pointer': isActive }"
            class="w-full h-full object-cover object-center"
            style="width: 960px;">
        <div v-if="isActive"
            :class="`cursor-pointer absolute flex flex-col top-0 right-0 text-white bg-gray-800 w-64 h-64 bg-opacity-80 justify-center items-center gap-y-4 p-8 ${this.rightMargin()}`">
            <div class="text-xl font-extrabold">{{game.name}}</div>
            <div class="text-xs">{{game.details.brief}}</div>
            <div v-if="game.state === 'preorder'" class="bg-ccb-amber-400 p-3 rounded-md font-semibold text-sm text-black mt-4">
                PREORDER
            </div>
            <div v-if="game.state === 'available'" class="bg-green-400 p-3 rounded-md font-semibold text-sm text-black mt-4">
                BUY NOW
            </div>
            <div v-if="game.state === 'coming soon'" class="bg-blue-400 p-3 rounded-md font-semibold text-sm text-black mt-4">
                LEARN MORE
            </div>
        </div>
    </div>
    </router-link>
</template>

<script>
import { models } from '@ccb/utils';
export default {
    props: {
       "game": models.Game,
       "isActive": {
           type: Boolean,
           default: true
        }
    },
    computed: {
        heroPath() {
            return `https://detangled.imgix.net/${this.game.details.hero}?auto=format`;
        }
    },
    methods: {
        rightMargin() {
            return `md:right-${4 + Math.floor(Math.random() * 5) * 5}`
        }
    }
}
</script>

